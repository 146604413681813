// ChangePassword.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Modal, Tag, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import styles from './UserSetting.module.css';

import { getProfile, updateProfile } from 'app-redux/slices/user';
import { AppDispatch, RootState } from 'app-redux/store';
import { useDispatch, useSelector } from 'react-redux';

interface UserSettingProps {
    visible: boolean;
    onCancel: () => void;
}

interface FormValues {
    prefixTextToSend: string,
    latePayNumberDayWarning: number
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const UserSetting: React.FC<UserSettingProps> = ({ visible, onCancel }) => {
    const dispatch: AppDispatch = useDispatch();
    const [tags, setTags] = useState(['MBBANK * 8120128989999 * DO XUAN THANH']);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { user } = useSelector((state: RootState) => state)

    const [form] = Form.useForm();
    const inputRef = useRef(null);

    const onFinish = (values: FormValues) => {
        console.log('Received values:', values);
        console.log(tags);
        try {
            const postData = {
                prefixTextToSend: values.prefixTextToSend,
                latePayNumberDayWarning: values.latePayNumberDayWarning,
                bankList: tags
            }
            dispatch(updateProfile(postData)).unwrap().then(() => {
                message.success("Cập nhật thành công")
            }).catch((error) => {
                message.error(error.message)
            });
        }
        catch (errorInfo) {
            console.log('Validation failed:', errorInfo);
        }
        onCancel(); // Close the modal
    };

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };
    const forMap = (tag) => {
        const tagElem = (
            <Tag
                closable
                onClose={(e) => {
                    e.preventDefault();
                    handleClose(tag);
                }}
                style={{ marginTop: 5 }}
            >
                {tag}
            </Tag>
        );
        return (
            <span
                key={tag}
                style={{
                    display: 'inline-block',
                }}
            >
                {tagElem}
            </span>
        );
    };
    const tagChild = tags.map(forMap);

    useEffect(() => {
        if (user.user !== null) {
            form.setFieldsValue(user.user.userSetting);
        }

    }, [user.user])

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(user.user.userSetting);
            setTags(user.user.userSetting.bankList);
        }
    }, [visible]);

    useEffect(() => {
        dispatch(getProfile());
    }, [])

    return (
        <Modal
            title="Cài đặt cá nhân"
            open={visible}
            onOk={form.submit}
            onCancel={onCancel}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}

                className={styles.changePasswordForm}
            >
                <Form.Item
                    label="Tiền tố gửi text"
                    name="prefixTextToSend"
                    rules={[{ required: true, message: 'Vui lòng nhập tiền tố gửi text!' }]}
                >
                    <Input placeholder='vd: Thầy Thành gửi thông báo h/s ' />
                </Form.Item>

                <Form.Item
                    label="Số ngày"
                    name="latePayNumberDayWarning"
                    rules={[{ required: true, message: 'Vui lòng nhập số ngày!' }]}
                >
                    <Input placeholder='Số ngày tối thiểu sẽ cảnh báo đỏ' />
                </Form.Item>
            </Form>

            <div className={styles.bankList}>
                <div> Tài khoản ngân hàng </div>
                <div>
                    {tagChild}

                    {inputVisible && (
                        <Input
                            ref={inputRef}
                            type="text"
                            size="small"
                            style={{
                                width: 200,
                                marginTop: 5
                            }}
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                        />
                    )}

                    {!inputVisible && (
                        <Tag onClick={showInput} style={{ background: "#fff", borderStyle: "dashed", marginTop: 5 }}>
                            <PlusOutlined /> Thêm tài khoản
                        </Tag>
                    )}

                </div>

            </div>
        </Modal>
    );
};

export default UserSetting;
