import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as APIService from "../../services/APIService"

export const addTutorClass = createAsyncThunk(
  "tutorClass/add",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "classes/add"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTutorClass = createAsyncThunk(
  "tutorClass/update",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "classes/update"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTutorClasses = createAsyncThunk(
  "tutorClass/get",
  async (_, { rejectWithValue }) => {
    try {
      const path = "classes"
      const response = await APIService.getData(path);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTutorClassesForMember = createAsyncThunk(
  "tutorClass/for-member",
  async (_, { rejectWithValue }) => {
    try {
      const path = "classes/for-member"
      const response = await APIService.getData(path);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTutorClassDetail = createAsyncThunk(
  "tutorClass/detail",
  async (payload:string, { rejectWithValue }) => {
    try {
      const path = "classes/detail"
      const response = await APIService.getData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeTutorClass = createAsyncThunk(
  "tutorClass/removeMember",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "classes/remove"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addMemberToClass = createAsyncThunk(
  "tutorClass/addMemberToClass",
  async (payload: {memberId:number, classId:number, startDate:string, endDate:string, discount:number}, { rejectWithValue }) => {
    try {
      const path = "classes/add_member"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeMemberFromClass = createAsyncThunk(
  "tutorClass/removeMemberFromClass",
  async (payload: {memberId:number, classId:string}, { rejectWithValue }) => {
    try {
      const path = "classes/remove-member"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const tutorClassSlice = createSlice({
  name: "tutorClass111",
  initialState: {
    tutorClasses: [],
    classDetail: null,
    currentPage: 1,
    error: "",
    successMessage: "",
    loading: false,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addTutorClass.pending, (state, action) => {
      console.log("pending")
      state.loading = true;
    }),
    builder.addCase(addTutorClass.fulfilled, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(addTutorClass.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(removeTutorClass.pending, (state, action) => {
      console.log("pending")
      state.loading = true;
    }),
    builder.addCase(removeTutorClass.fulfilled, (state, action) => {
      state.loading = false;
      console.log("fullfilled")
      console.log(action.payload)
    }),
    builder.addCase(removeTutorClass.rejected, (state, action) => {
      state.loading = false;
      console.log("rejected")
      console.log(action.payload)
    }),
    builder.addCase(getTutorClasses.pending, (state, action) => {
      console.log("pending")
      state.loading = true;
    }),
    builder.addCase(getTutorClasses.fulfilled, (state, action) => {
      console.log("pending")
      state.loading = false;
      state.tutorClasses = action.payload.map((item, index) => {
        item["key"] = item.id
        item["numberIndex"] = index + 1
        item["numberOfAttends"] = item.attends.length
        item["createdAt"] = item.createdAt.slice(0, 10)
        return item
      });
    }),
    builder.addCase(getTutorClasses.rejected, (state, action) => {
      state.loading = false;
      console.log("rejected")
      console.log(action.payload)
    }),

    builder.addCase(getTutorClassesForMember.pending, (state, action) => {
      console.log("pending")
      state.loading = true;
    }),
    builder.addCase(getTutorClassesForMember.fulfilled, (state, action) => {
      console.log("pending")
      state.loading = false;
      state.tutorClasses = action.payload.map((item, index) => {
        item["key"] = item.id
        item["numberIndex"] = index + 1
        item["numberOfAttends"] = item.attends.length
        item["createdAt"] = item.createdAt.slice(0, 10)
        return item
      });
    }),
    builder.addCase(getTutorClassesForMember.rejected, (state, action) => {
      state.loading = false;
      console.log("rejected")
      console.log(action.payload)
    }),
    builder.addCase(getTutorClassDetail.fulfilled, (state, action) => {
      console.log("fulfilled")
      state.loading = false;
      state.classDetail = action.payload;
    })
  }
});

export const { setCurrentPage } = tutorClassSlice.actions;

export default tutorClassSlice.reducer;