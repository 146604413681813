import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as APIService from "../../services/APIService"
import { TimeTable } from "../../types/timeTable/TimeTable";

export const addTimeTable = createAsyncThunk(
  "timeTable/add",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "time_tables/add"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTimeTable = createAsyncThunk(
  "timeTable/update",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "time_tables/update"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTimeTables = createAsyncThunk(
  "timeTable/get",
  async (_, { rejectWithValue }) => {
    try {
      const path = "time_tables"
      const response = await APIService.getData(path);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeTimeTable = createAsyncThunk(
  "timeTable/remove",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "time_tables/remove"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


interface TimeTableState {
  timeTables: TimeTable[];
  currentPage: number;
  numberOfPages: number | null;
  error: string;
  successMessage: string;
  loading: boolean;
}

const initialState: TimeTableState = {
  timeTables: [],
  currentPage: 1,
  numberOfPages: null,
  error: '',
  successMessage: '',
  loading: false,
};


const timeTableSlice = createSlice({
  name: 'timeTable',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTimeTable.pending, (state) => {
        console.log('pending');
        state.loading = true;
      })
      .addCase(addTimeTable.fulfilled, (state) => {
        state.loading = false;
        console.log('fulfilled');
      })
      .addCase(addTimeTable.rejected, (state, action) => {
        state.loading = false;
        console.log('rejected');
        console.log(action.payload);
      })
      .addCase(getTimeTables.pending, (state) => {
        console.log('pending');
        state.loading = true;
      })
      .addCase(getTimeTables.fulfilled, (state, action) => {
        console.log('fulfilled');
        state.loading = false;
        state.timeTables = action.payload.map((item: TimeTable, index: number) => {
          const timeTableData = item.timeTable.map((arrayItem) => {
            const hourPart = Math.floor(arrayItem.time);
            const minutePart = (arrayItem.time - hourPart) * 60; // 60 minutes per hour

            const formattedHour = hourPart < 10 ? `0${hourPart}` : `${hourPart}`;
            const formattedMinute = minutePart === 0 ? '00' : `${minutePart}`;

            arrayItem.formattedTime = `${formattedHour}:${formattedMinute}` //Refactoring: add minute and hour to TimeTableItem

            return { ...arrayItem, hour: hourPart, minute: minutePart };
          });
          const newItem = {
            ...item,
            numberIndex: index + 1,
            key: item.id,
            name: item.tutorClass.name,
            timeTableData: timeTableData,
          };
          return newItem;
        });
      })
      .addCase(getTimeTables.rejected, (state, action) => {
        state.loading = false;
        console.log('rejected');
        console.log(action.payload);
      });
  },
});

export const { setCurrentPage } = timeTableSlice.actions;

export default timeTableSlice.reducer;