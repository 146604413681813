import { configureStore } from "@reduxjs/toolkit";
import TutorClassReducer from "./slices/tutorClass";
import MemberReducer from "./slices/member";
import AuthReducer from "./slices/auth";
import UserReducer from "./slices/user";
import AttendDetailReducer from "./slices/attendDetail";
import TimeTableReducer from "./slices/timeTable";
import ReportReducer from "./slices/report";
import examSlice from "./slices/exam";

export const store = configureStore({
  reducer: {
    auth:AuthReducer,
    user:UserReducer,
    tutorClass: TutorClassReducer,
    member: MemberReducer,
    attendDetail: AttendDetailReducer,
    timeTable: TimeTableReducer, 
    report: ReportReducer,
    [examSlice.reducerPath]: examSlice.reducer, 
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(examSlice.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;