import React, { useState } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';
import { useUploadFileMutation } from 'app-redux/slices/file';
import { UploadedFileResponse } from 'types/file/UploadFileResponse';

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: UploadedFileResponse) => void;
}

const UploadPhoto: React.FC<UploadModalProps> = ({ isOpen, onClose, onUpload }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadFile] = useUploadFileMutation();

  const handleChange = (info) => {
    console.log("abelhoang")
    console.log(info)

    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      onUpload(info.file.response);
      onClose();
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(info.fileList);
  };

  const beforeUpload = (file: UploadFile) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage;
  };

  const handleUploadFile =  async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('file', file); 

    try {
      const responseData = await uploadFile(formData).unwrap();
      console.log("upload success")
      onSuccess(responseData)
    } catch (error) {
      console.log("upload fail")
      onError(new Error('Upload failed'))
    }
  }

  return (
    <Modal
      title="Upload ảnh"
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Upload
        accept="image/*"
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        showUploadList={false}
        customRequest={handleUploadFile}

      >
        <div style={{ border: '2px dashed #d9d9d9', padding: '20px', textAlign: 'center' }}>
          <UploadOutlined style={{ fontSize: 24 }} />
          <p>Bấm vào đây hoặc kéo file vào khu vực này để upload</p>
        </div>
      </Upload>
      {fileList.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <strong>File đã chọn:</strong> {fileList[0].name}
        </div>
      )}
      <div style={{ marginTop: 16 }}>
        <Button type="primary" onClick={() => fileList.length > 0}>
          Upload
        </Button>
        <Button style={{ marginLeft: 8 }} onClick={onClose}>
          Huỷ
        </Button>
      </div>
    </Modal>
  );
};

export default UploadPhoto;
