import { Tabs, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { connect, useDispatch } from "react-redux";
import { getTutorClassDetail } from "app-redux/slices/tutorClass"
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from 'app-redux/store';

import { useSelector } from "react-redux";
import styles from './ClassDetail.module.css';
import ClassMember from '../ClassMember/ClassMember';
import ExamList from '../exam/ExamList/ExamList';

const ClassDetail: React.FC = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const { tutorClass, auth } = useSelector((state: RootState) => state)
  const { classId } = useParams();

  useEffect(() => {
    console.log(classId);
    dispatch(getTutorClassDetail(`classId=${classId}`));
  }, [])

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const items = [
    {
      key: '1',
      label: 'Danh sách học viên',
      disabled: auth.isMemberType, // Disable if auth.isMemberType is true
      children: <ClassMember />, // Content for the "Danh sách học viên" tab
    },
    {
      key: '2',
      label: 'Bài tập',
      children: <ExamList />, // Content for the "Bài tập" tab
    },
  ];

  return (
    <div>
      <div className={styles.classNameSection}>
         <span> <b>Lớp:</b> {tutorClass.classDetail !== null ? tutorClass.classDetail.name : ""} </span>
         <span>Trạng thái hoạt động <Switch defaultChecked onChange={onChange} /> </span>
      </div>
      <Tabs defaultActiveKey="2" items={items} />
    </div>
  )
}

export default ClassDetail;
