// ChangePassword.tsx
import React from 'react';
import { Form, Input, Modal, message } from 'antd';

import styles from './ChangePassword.module.css';

import { changePasswordAsync } from '../../../app-redux/slices/auth';
import { AppDispatch } from '../../../app-redux/store';
import { useDispatch } from 'react-redux';

interface ChangePasswordProps {
    visible: boolean;
    onCancel: () => void;
}

interface FormValues {
    currentPassword: string,
    newPassword: string
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ visible, onCancel }) => {
    const dispatch: AppDispatch = useDispatch();

    const onFinish = (values: FormValues) => {
        // Handle password change logic here
        console.log('Received values:', values);
        try {
            dispatch(changePasswordAsync({ password: values.currentPassword, newPassword: values.newPassword })).unwrap().then(() => {
                message.success("Thay đổi mật khẩu thành công")
            }).catch((error) => {
                message.error(error.message)
            });
        }
        catch (errorInfo) {
            console.log('Validation failed:', errorInfo);
        }
        onCancel(); // Close the modal
    };

    const [form] = Form.useForm();

    return (
        <Modal
            title="Thay đổi mật khẩu"
            open={visible}
            onOk={form.submit}
            onCancel={onCancel}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}

                className={styles.changePasswordForm}
            >
                <Form.Item
                    label="Mật khẩu"
                    name="currentPassword"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Mật khẩu mới"
                    name="newPassword"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Nhập lại MK mới"
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Vui lòng nhập lại mật khẩu mới!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Nhập lại mật khẩu mới không khớp!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                {/* <Form.Item>
          <button type="submit">Change Password</button>
        </Form.Item> */}
            </Form>
        </Modal>
    );
};

export default ChangePassword;
