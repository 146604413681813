import React from 'react';
import { Input, Button, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { AppDispatch } from '../../../app-redux/store';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import styles from './Register.module.css';

import { registerUser } from '../../../app-redux/slices/auth';

interface FormValues {
  email: string,
  password: string
  phone: string,
  name: string
}

const Register: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values: FormValues) => {

    console.log('Received values:', values);
    try {
      dispatch(registerUser({ email: values.email, password: values.password, phone: values.phone, name: values.name })).unwrap().then(() => {
        message.success("Đăng ký thành công")
        navigate("/login");
      }).catch((error) => {
        message.error(error.message)
      });
    }
    catch (errorInfo) {
      console.log('Validation failed:', errorInfo);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginBox}>
        <h1>Đăng ký</h1>
        <Form onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Vui lòng nhập tên của bạn!' }]}
          >
            <Input placeholder="Tên của bạn" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: 'Vui lòng nhập SĐT!' }]}
          >
            <Input placeholder="Số điện thoại" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
          >
            <Input.Password placeholder="Mật khẩu" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Đăng ký
            </Button>
          </Form.Item>
        </Form>
        <div className={styles.registerLink}>
          <p>
            Đã có tài khoản? <Link to="/login">Đăng nhập</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
