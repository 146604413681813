import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'services/BaseRTKQuery';
import { DataResponse } from 'types/DataResponse';
import { Exam } from 'types/exam/Exam';
import { ExamResult } from 'types/exam/ExamResult';
import { AnswerExamData } from 'types/exam/request/AnswerExamData';

const examSlice = createApi({
    reducerPath: 'exam',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getExams: builder.query<Exam[], number>({
            query: (classId) => `exams/list?classId=${classId}`,
        }),
        getExamDetail: builder.query<Exam, string>({
            query: (examId) => `exams/detail?examId=${examId}`,
        }),
        getExamDetailShuffleAnswers: builder.query<Exam, string>({
            query: (examId) => `exams/detail-shuffle-answers?examId=${examId}`,
        }),
        getExamResult: builder.query<ExamResult[], string>({
            query: (examId) => `exams/result?examId=${examId}`,
        }),
        createExam: builder.mutation<DataResponse, Partial<Exam>>({
            query: (bodyData) => ({
                url: 'exams/add',
                method: 'POST',
                body: bodyData,
            }),
        }),
        updateExam: builder.mutation<DataResponse, Partial<Exam>>({
            query: (bodyData) => ({
                url: 'exams/update',
                method: 'POST',
                body: bodyData,
            }),
        }),
        answerExam: builder.mutation<DataResponse, AnswerExamData>({
            query: (bodyData) => ({
                url: 'exams/answer-exam',
                method: 'POST',
                body: bodyData,
            }),
        }),
        deleteExam: builder.mutation<DataResponse, string>({
            query: (examId) => ({
                url: 'exams/delete',
                method: 'POST',
                body: {examId},
            }),
        }),
        addQuestions: builder.mutation<DataResponse, unknown>({
            query: (bodyData) => ({
                url: 'exams/add-questions',
                method: 'POST',
                body: bodyData,
            }),
        }),

        importQuestions: builder.mutation<DataResponse, unknown>({
            query: (bodyData) => {
                return ({
                    url: 'exams/import-questions',
                    method: 'POST',
                    body: bodyData,
                })
            },
        }),

        updateQuestion: builder.mutation<DataResponse, unknown>({
            query: (bodyData) => ({
                url: 'exams/update-question',
                method: 'POST',
                body: bodyData,
            }),
        }),
        deleteQuestionImage: builder.mutation<DataResponse, unknown>({
            query: (bodyData) => ({
                url: 'exams/delete-question-image',
                method: 'POST',
                body: bodyData,
            }),
        }),
        deleteQuestion: builder.mutation<DataResponse, unknown>({
            query: (bodyData) => ({
                url: 'exams/delete-question',
                method: 'POST',
                body: bodyData,
            }),
        }),
    }),
});

export const { 
    useGetExamsQuery, 
    useGetExamResultQuery,
    useGetExamDetailQuery, 
    useGetExamDetailShuffleAnswersQuery,
    useCreateExamMutation, 
    useUpdateExamMutation,
    useDeleteExamMutation,
    useAddQuestionsMutation,
    useUpdateQuestionMutation,
    useDeleteQuestionMutation, 
    useDeleteQuestionImageMutation,
    useAnswerExamMutation,
    useImportQuestionsMutation
 } = examSlice;
export default examSlice;