import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as APIService from "../../services/APIService"
import { Member } from "types/member/Member";

export const addMember = createAsyncThunk<unknown, Member | undefined>(
  "members/add",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "members/add"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMember = createAsyncThunk(
  "members/update",
  async (payload: Member, { rejectWithValue }) => {
    try {
      const path = "members/update"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getMembers = createAsyncThunk<unknown, string|undefined>(
  "members",
  async (payload, thunkAPI?) => {
    try {
      const path = "members"
      const response = await APIService.getData(path, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const removeMember = createAsyncThunk(
  "members/member",
  async (payload: { memberId: number }, { rejectWithValue }) => {
    try {
      const path = "members/remove"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const memberSlice = createSlice({
  name: "member",
  initialState: {
    members: [],
    classMembers: [],
    currentPage: 1,
    numberOfPages: null,
    error: '',
    successMessage: "",
    loading: false,
    totalRecords: 0,
    totalRecordsClassMembers: 0,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMember.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(addMember.fulfilled, (state, action) => {
        state.loading = false;
        console.log("fullfilled")
        console.log(action.payload)
      })
      .addCase(addMember.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected")
        console.log(action.payload)
      })
      .addCase(updateMember.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.loading = false;
        console.log("fullfilled")
        console.log(action.payload)
      })
      .addCase(updateMember.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected")
        console.log(action.payload)
      })
      .addCase(removeMember.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(removeMember.fulfilled, (state, action) => {
        state.loading = false;
        console.log("fullfilled")
        console.log(action.payload)
      })
      .addCase(removeMember.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected")
      })
      .addCase(getMembers.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        console.log("pending")
        state.loading = false;
        const tempTotalRecords = action.payload[1] //Dữ liệu trả về gồm cả số lượng records, và dữ liệu array các bản ghi.
        const tempMembers = action.payload[0].map((item, index) => {
          item["key"] = item.attendId
          item["numberIndex"] = index + 1
          item["attendStopped"] = (new Date(item.endDate) < new Date())
          item["createdAt"] = item.createdAt.slice(0, 10)
          if (item.startDate) item["startDate"] = item.startDate.slice(0, 10)
          if (item.endDate) item["endDate"] = item.endDate.slice(0, 10)
          return item
        });
        if (action.meta.arg.includes("classId")){
          state.classMembers = tempMembers
          state.totalRecordsClassMembers = tempTotalRecords;
        }
        else{
          state.members = tempMembers;
          state.totalRecords  = tempTotalRecords;
        }
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const { setCurrentPage } = memberSlice.actions;

export default memberSlice.reducer;