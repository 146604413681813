import React, { Component, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Popconfirm, Row, Space, Table, Modal, Form, Input, DatePicker } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useGetExamsQuery, useCreateExamMutation, useUpdateExamMutation, useDeleteExamMutation } from 'app-redux/slices/exam';
import { formatDate } from 'helper/Helper';
import moment from 'moment';
import { RootState } from 'app-redux/store';
import { useSelector } from 'react-redux';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const ExamList: React.FC = () => {
    const { classId } = useParams()
    const { auth } = useSelector((state: RootState) => state)
    const { data, error, isLoading, refetch } = useGetExamsQuery(Number(classId))
    const [formTitle, setFormTitle] = useState("Thêm bài tập")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddMode, setIsAddMode] = useState(true)
    const [workingIndex, setWorkingIndex] = useState(0)
    const [form] = Form.useForm();
    const [createExam] = useCreateExamMutation()
    const [updateExam] = useUpdateExamMutation()
    const [deleteExam] = useDeleteExamMutation()
    const navigate = useNavigate()


    const columns = [
        {
            title: '#',
            dataIndex: 'numberIndex',
            width: 60
        },
        {
            title: 'Tên bài tập',
            dataIndex: 'name',
            width: 250
        },
        {
            title: 'Mô tả',
            dataIndex: 'desc',
            width: 200
        },
        {
            title: 'Số học sinh đã làm',
            dataIndex: 'numberSubmits'
        },
        {
            title: 'Hết hạn',
            dataIndex: 'expiresAt',
            width: 150
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            width: 150
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 120,
            render: (_, record, index) => (
                <Space size="middle">
                    {!auth.isMemberType && (<a onClick={() => onEditClick(index)} >
                        <EditOutlined />
                    </a>
                    )}
                    {!auth.isMemberType && (
                        <Popconfirm
                        title="Chắc chắn xoá bài tập này"
                        onConfirm={() => onDeleteClick(index)}
                        okText="Đồng ý"
                        cancelText="Đóng lại"
                        okType="danger"
                    >
                        <a href="#">
                            <DeleteOutlined />
                        </a>
                    </Popconfirm>
                    )}
                    <a onClick={() => onDetailClick(index)}>
                        <EyeOutlined />
                    </a>
                </Space>
            ),
        },
    ];

    const onDeleteClick = async (index) => {
        const exams = data || []
        const examId = exams[index]._id
        try {
            const result = await deleteExam(examId).unwrap();
            console.log('Exam updated:', result);
        } catch (error) {
            console.error('Failed to update exam:', error);
        }
    }

    const onEditClick = async (index) => {
        const exams = data || [];
        setIsAddMode(false)
        setFormTitle("Sửa bài tập")
        setIsModalOpen(true)
        setWorkingIndex(index)
        const aExam = exams[index]
        const tempDate = new Date(aExam.expiresAt)

        const temp = { ...aExam, expiresAt: moment(tempDate) }
        form.setFieldsValue(temp)
    }

    const onDetailClick = (index) => {
        const exams = data || []
        const examId = exams[index]._id //Todo need fixed
        navigate(`/exam/result/${examId}`);
    }

    const onAddClick = async (index) => {
        setIsModalOpen(true)
        setIsAddMode(true)
        form.resetFields()
        setFormTitle("Thêm bài tập")
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        if (isAddMode) {
            const examData = {
                ...values,
                "numberQuestions": 0,
                "expiresAt": values.expiresAt,
                classId
            }
            try {
                const result = await createExam(examData).unwrap();
                console.log('Exam created:', result);
                refetch()
            } catch (error) {
                console.error('Failed to create exam:', error);
            }
        }
        else {

            const exams = data || []
            const examId = exams[workingIndex]._id
            const examData = {
                name: values.name,
                desc: values.desc,
                expiresAt:values.expiresAt,
                examId
            }
            try {
                const result = await updateExam(examData).unwrap();
                refetch()
            } catch (error) {
                console.error('Failed to update exam:', error);
            }
        }
        setIsModalOpen(false);
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {"some error"}</div>;


    const exams = data || [];
    const dataSource = exams.map((aExam, index) => {
        const createdAt = new Date(aExam.createdAt)
        const expiredAt = new Date(aExam.expiresAt)
        return {
            numberIndex: index + 1,
            name: aExam.name,
            desc: aExam.desc,
            numberSubmits: 0,
            expiresAt: formatDate(expiredAt, true),
            createdAt: formatDate(createdAt)
        }
    })
    return (
        <div>
            <Modal title={formTitle} open={isModalOpen} onOk={form.submit} onCancel={handleCancel}>
                <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                    <Form.Item name="name" label="Tên bài tập" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="desc" label="Ghi chú" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="expiresAt" label="Hết hạn" rules={[{ required: true }]}>
                        <DatePicker showTime
                            format="YYYY-MM-DD HH:mm" />
                    </Form.Item>
                </Form>
                <span style={{ fontStyle: "italic" }}>Dấu (<span style={{ color: "red" }}>*</span>) là bắt buộc nhập dữ liệu </span>
            </Modal>
            <Row justify="end" style={{ paddingBottom: 10 }}>
                <Space>
                    {!auth.isMemberType && <Button type="primary" onClick={onAddClick}>Thêm bài tập</Button>}
                </Space>
            </Row>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 1000 }} size='small' pagination={{ pageSize: 100, total: dataSource.length }} />
        </div>
    )

}

export default ExamList