import { Space, Table, Row, Button, Popconfirm, Tag} from 'antd';
import { connect, useDispatch } from "react-redux";
import { addTimeTable, updateTimeTable, getTimeTables, removeTimeTable } from "app-redux/slices/timeTable"
import React, { useEffect, useState } from "react";
import { showSuccessMessage, showErrorMessage } from "helper/Helper"
import AddTimeTable from "./AddTimeTable"

import { useSelector } from "react-redux";

import styles from './TimeTable.module.css';

const TimeTable = (props) => {
  const dispatch = useDispatch();
  const { timeTable } = useSelector(state => state)
  const [isAddMode, setIsAddMode] = useState(true)
  const [formTitle, setFormTitle] = useState("Thêm lớp")
  const [timeTableId, setTimeTableId] = useState(0)
  const [timeTableSettingVisible, setTimeTableSettingVisible] = useState(false);
  const [workingTimeTable, setWorkingTimeTable] = useState();

  useEffect(() => {
    dispatch(getTimeTables())
  }, [])

  useEffect(() => {

    if (timeTable.error) {
      alert(timeTable.error)
    }

  }, [timeTable])

  const handleCancelUserSetting = () => {
    setTimeTableSettingVisible(false)
  }

  const onAddClick = () => {
    setIsAddMode(true)
    setFormTitle("Thêm TKB")

    setTimeTableSettingVisible(true)
  }

  const handlePopupFinish = (bodyData) => {
    setTimeTableSettingVisible(false)

    console.log({ bodyData })
    if (isAddMode) {
      dispatch(addTimeTable(bodyData)).unwrap().then((_) => {
        dispatch(getTimeTables());
        showSuccessMessage("Thêm thành công")
      })
        .catch((e) => {
          showErrorMessage(e.message)
        })
    }
    else {
      const updateData = { ...bodyData, timeTableId: timeTableId }
      dispatch(updateTimeTable(updateData)).unwrap().then((_) => {
        dispatch(getTimeTables());
        showSuccessMessage("Sửa thành công")
      })
        .catch((e) => {
          showErrorMessage(e.message)
        })
    }
  }

  const onEditClick = (index) => {
    setIsAddMode(false)
    const timeTableObj = timeTable.timeTables[index]
    setTimeTableId(timeTableObj.id)
    setWorkingTimeTable(timeTableObj);
    setFormTitle("Sửa TKB")
    setTimeTableSettingVisible(true)

  }

  const onDeleteClick = (index) => {
    console.log(index);
    let payload = {
      timeTableId: timeTable.timeTables[index].id
    }
    dispatch(removeTimeTable(payload)).unwrap().then((_) => {
      dispatch(getTimeTables());
      showSuccessMessage("Xoá thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      });
  }

  const timeTableLabels = (timeTableData) => {
    return (
      <div>
        {
          timeTableData.map((item, index) => {
            return (
              <Tag color='green' key={index}>
                {`${item.dayInWeek} ${item.hour}:${item.minute}`}
              </Tag>
            )
          })
        }
      </div>
    )
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'numberIndex',
    },
    {
      title: 'Tên lớp',
      dataIndex: 'name',
    },
    {
      title: 'Lịch học',
      dataIndex: 'timeTableData',
      render: (_, { timeTableData }) => timeTableLabels(timeTableData)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => onEditClick(index)}>Sửa</a>
          <Popconfirm
            title="Chắc chắn xoá dữ liệu này?"
            onConfirm={() => onDeleteClick(index)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Xoá</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onChange = (value) => {
    console.log('changed', value);
  };

  return (
    <div>
      <AddTimeTable
        visible={timeTableSettingVisible}
        onCancel={handleCancelUserSetting}
        timeTable={workingTimeTable}
        popupTitle={formTitle}
        onPopupFinish={handlePopupFinish}
      />

      <h1 className={styles.mainTitle}> Cài đặt TKB </h1>
      <Row justify="end" style={{paddingBottom: 10}}>
        <Space>
          <Button type="primary" onClick={onAddClick}>Thêm TKB</Button>
        </Space>
      </Row>
      <Table columns={columns} dataSource={timeTable.timeTables} pagination={{ pageSize: 1000 }} />
    </div>
  )
}

export default connect(null, { addTimeTable })(TimeTable);
