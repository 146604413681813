import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'services/BaseRTKQuery';
import { UploadedFileResponse } from 'types/file/UploadFileResponse';

const uploadFileSlice = createApi({
    reducerPath: 'file',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        
        uploadFile: builder.mutation<UploadedFileResponse, FormData>({
            query: (bodyData) => {
                return ({
                    url: 'files/upload',
                    method: 'POST',
                    body: bodyData,
                })
            },
        })
    }),
});

export const { 
    useUploadFileMutation
 } = uploadFileSlice;
export default uploadFileSlice;