import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as APIService from "../../services/APIService"

export const getTimeTables = createAsyncThunk(
    "reports",
    async (payload, { rejectWithValue }) => {
      try {
        const path = "reports/time_tables"
        const response = await APIService.getData(path, payload);
        console.log("Data returned")
        console.log(response.data)
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  const reportSlice = createSlice({
    name: "reports",
    initialState: {
      timeTables: [],
      currentPage: 1,
      numberOfPages: null,
      error: "",
      successMessage:"",
      loading: false,
    },
    reducers: {
      setCurrentPage: (state, action) => {
        state.currentPage = action.payload;
      },
    },
    extraReducers: (builder) =>  { 
      builder
      .addCase(getTimeTables.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(getTimeTables.fulfilled, (state, action) => {
        console.log("pending")
        state.loading = false;
        
        const tempList = action.payload.map((item, index) => {
            item["key"] = item.id
            item["numberIndex"] = index + 1
            
            item["mon"] = {...item.dayList[0], "name":"Đã nghỉ"}
            item["tue"] = {...item.dayList[1], "name":"ABC"}
            item["wed"] = {...item.dayList[2], "name":"ABC"}
            item["thu"] = {...item.dayList[3], "name":"ABC"}
            item["fri"] = {...item.dayList[4], "name":"ABC"}
            item["sat"] = {...item.dayList[5], "name":"ABC"}
            item["sun"] = {...item.dayList[6], "name":"ABC"}
  
            return item
          });
        state.timeTables = tempList

        console.log(
            "%ctimeTables!",
            "color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold"
          );

        console.table(tempList)
      })
      .addCase(getTimeTables.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected")
        console.log(action.payload)
        state.error = action.payload.message;
      })
    },
  });
  
  export const { setCurrentPage } = reportSlice.actions;
  
  export default reportSlice.reducer;