import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { Button, Row, Space } from "antd";

import styles from "./ExamQuestionList.module.css"
import QuestionItem from '../QuestionItem/QuestionItem';
import { useGetExamDetailQuery, useDeleteQuestionMutation, useUpdateQuestionMutation, useDeleteQuestionImageMutation } from 'app-redux/slices/exam';
import AddQuestion from '../AddQuestion/AddQuestion';
import { Question } from 'types/exam/Question';
import { Exam } from 'types/exam/Exam';
import { showErrorMessage } from 'helper/Helper';
import { UploadedFileResponse } from 'types/file/UploadFileResponse';
import UploadFileModal from '../UploadFile/UploadFile';

const ExamQuestionList: React.FC = () => {
    const { examId } = useParams();
    const [editingQuestion, setEditingQuestion] = useState<Question>(null)
    const [isEditing, setIsEditing] = useState(false)
    const [modalTitle, setModalTitle] = useState("Tạo câu hỏi")
    const [isOpened, setIsOpened] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const { data, error, isLoading, refetch } = useGetExamDetailQuery(examId)
    const [deleteQuestion] = useDeleteQuestionMutation()
    const [updateQuestion] = useUpdateQuestionMutation()
    const [deleteQuestionImage] = useDeleteQuestionImageMutation();

    const questionItemEditClick = (questionId: string) => {
        const question = data.questions.find(aQuestion => aQuestion._id === questionId)
        setEditingQuestion(question)
        showModal(true)
    }

    const questionItemDeleteClick = async (questionId: string) => {
        try {
            const deleteQuestionData = {
                questionId: questionId
            }
            const result = await deleteQuestion(deleteQuestionData).unwrap();
            console.log('question deleted:', result);
            refetch()

        } catch (error) {
            showErrorMessage("Failed to delete question")
        }
    }

    const quesitonItemUploadFile = async (questionId: string, fileResponse: UploadedFileResponse) => {
        const updateQuestionData = {
            questionId: questionId,
            imagePath: fileResponse.filePath,
            imageFileId: fileResponse.id
        }

        try {
            const result = await updateQuestion(updateQuestionData).unwrap();
            console.log('question updated:', result);
            refetch()
                ;
        } catch (error) {
            console.error('Failed to update question:', error);
        }
    }

    const quesitonItemDeleteImage = async (questionId: string) => {
        try {
            const deleteQuestionData = {
                questionId: questionId
            }
            const result = await deleteQuestionImage(deleteQuestionData).unwrap();
            console.log('question image deleted:', result);
            refetch()

        } catch (error) {
            showErrorMessage("Failed to delete question")
        }
    }

    const renderQuestion = (exam: Exam) => {
        return exam.questions.map((question, index) => (
            <div key={question._id} className={styles.questionItem}>
                <QuestionItem question={question} questionIndex={index} shouldShowHeader={true}
                    quetionItemEditClick={questionItemEditClick}
                    quetionItemDeleteClick={questionItemDeleteClick}
                    quesitonItemUploadFile={quesitonItemUploadFile}
                    quesitonItemDeleteImage={quesitonItemDeleteImage}
                />
            </div>
        ))
    }

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {"some error"}</div>;

    const exam = data;
    if (data === undefined) {
        <div>Error: {"some error"}</div>;
    }

    const showModal = (editing = true) => {
        const tempTitle = editing ? "Sửa câu hỏi" : "Tạo câu hỏi"
        setIsEditing(editing)
        setModalTitle(tempTitle)
        setIsOpened(true);
    };

    const handleAddClick = () => {
        setEditingQuestion(null)
        setIsEditing(false)
        showModal(false)
    }

    const handleImportClick = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        refetch()
        setIsOpened(false);
    };

    const handleCloseModalImportQuestion = () => {
        setModalOpen(false)
    };

    const handleUpload = () => {
        refetch()
    };

    return (
        <div>
            <UploadFileModal
                isOpen={modalOpen}
                examId={examId}
                onClose={handleCloseModalImportQuestion}
                onUpload={handleUpload}
            />

            <Row justify="end" style={{ paddingBottom: 10 }}>
                <Space>
                    
                </Space>
                <Space>
                    <Button type="primary" onClick={handleImportClick}>Import câu hỏi</Button>
                    <Button type="primary" onClick={handleAddClick}>Thêm câu hỏi</Button>
                </Space>
            </Row>
            <AddQuestion isOpened={isOpened} onClose={handleCloseModal} examId={examId} isEditing={isEditing} editingQuestion={editingQuestion} modalTitle={modalTitle} />
            <div className={styles.examName}>
                <span>{exam.name}, {exam.desc}</span>
            </div>
            {renderQuestion(exam)}
        </div>
    )

}

export default ExamQuestionList