import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Row, Col, Radio } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useAddQuestionsMutation, useUpdateQuestionMutation } from 'app-redux/slices/exam';
import { Question } from 'types/exam/Question';

interface AddQuestionProps {
  isOpened: boolean;
  onClose: () => void;
  examId: string, 
  isEditing: boolean,
  editingQuestion?: Question,
  modalTitle:string
}

const AddQuestion: React.FC<AddQuestionProps> = ({ isOpened, onClose, examId, isEditing, editingQuestion, modalTitle }) => {
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState<string[]>(['', '', '', '']);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);

  const [addQuestionsToExam] = useAddQuestionsMutation()
  const [updateQuestion] = useUpdateQuestionMutation()

  const handleAnswerChange = (index: number, value: string) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleCorrectAnswerChange = (index) => {
    setCorrectAnswerIndex(index);
  };
  
  const addAnswer = () => {
    setAnswers([...answers, '']);
  };

  const removeAnswer = (index: number) => {
    const newAnswers = answers.filter((_, i) => i !== index);
    setAnswers(newAnswers);
  };

  const handleSubmit = async () => {
    if (isEditing){
      const answerListForUpdate = answers
      .filter(item => item !== "")
      .map((item, index) => {
        const isCorrect = index === correctAnswerIndex 
        
        return {
          answer: item,
          isCorrect, 
          _id: editingQuestion.answers[index]._id
        }
      })
      const updateQuestionData = {
        questionId: editingQuestion._id, 
        question,
        answers: answerListForUpdate
      }

      try {
        const result = await updateQuestion(updateQuestionData).unwrap();
        console.log('question updated:', result);
  
      } catch (error) {
        console.error('Failed to update question:', error);
      }
      onClose()
      return
    }
    
    //handle Add questions
    const answerList = answers
    .filter(item => item !== "")
    .map((item, index) => {
      const isCorrect = index === correctAnswerIndex
      return {
        answer: item,
        isCorrect
      }
    })

    const addQuestionsData = {
      examId: examId,
      questions: [
        {
          question,
          answers: answerList
        }
      ]
    }

    try {
      const result = await addQuestionsToExam(addQuestionsData).unwrap();
      console.log('Exam created:', result);

    } catch (error) {
      console.error('Failed to create exam:', error);
    }

    onClose(); // Close the modal after submission
  };

  useEffect(() => {
      if (editingQuestion !== undefined && editingQuestion !== null){
         const tempAnswers = editingQuestion.answers.map(item => item.answer)
         setAnswers(tempAnswers)
         setQuestion(editingQuestion.question)
         
         let index = 0;
         for(const each of editingQuestion.answers){
          if (each.isCorrect){
            setCorrectAnswerIndex(index)
            break
          }
          index++;
         }
      }
      else{
        setAnswers(['', '', '', ''])
        setQuestion("")
        setCorrectAnswerIndex(0)
      }
  }, [editingQuestion])

  return (
    <Modal
      title={modalTitle}
      open={isOpened}
      onCancel={onClose}
      footer={null}
    >
      <Input
        placeholder="Nhập câu hỏi"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      {answers.map((answer, index) => (
        <Row key={index} gutter={8} style={{ marginBottom: 8 }}>
          <Col span={1}>
        <Radio
          checked={correctAnswerIndex === index}
          onChange={() => handleCorrectAnswerChange(index)}
        />
      </Col>
          <Col span={19}>
            <Input
              placeholder={`Câu trả lời ${index + 1}${index === 0 ? ' [đáp án đúng]' : ''}`}
              value={answer}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
            />
          </Col>
          <Col span={4}>
            <Button
              icon={<CloseCircleOutlined />}
              disabled={isEditing}
              type="link"
              onClick={() => removeAnswer(index)}
            />
          </Col>
        </Row>
      ))}
      <Button type="dashed" disabled={isEditing} onClick={addAnswer} style={{ marginBottom: 16 }}>
        Thêm câu trả lời
      </Button>
      <br />
      <Button type="primary" onClick={handleSubmit}>
        Cập nhật
      </Button>
    </Modal>
  );
};

export default AddQuestion;
