import React, { Component, useEffect } from 'react';
import { Input, Button, RadioChangeEvent, Radio, Popconfirm, UploadFile } from 'antd'
import { Image } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import styles from './QuestionItem.module.css'
import { useState } from 'react';
import { Question } from 'types/exam/Question';
import UploadPhoto from 'components/UploadPhoto/UploadPhoto';
import { UploadedFileResponse } from 'types/file/UploadFileResponse';
import MathJaxComponent from 'components/MathJax/MathJax';

interface QuestionItemProps {
    question: Question
    questionIndex: number
    shouldShowHeader?: boolean // = false when taking exam, = true for exam creator
    quetionItemEditClick?: (questionId: string) => void
    quetionItemDeleteClick?: (questionId: string) => void
    questionItemAnswer?: (questionId: string, answerId: string) => void
    quesitonItemUploadFile?: (QuestionId: string, fileResponse: UploadedFileResponse) => void
    quesitonItemDeleteImage?: (QuestionId: string) => void
}

const QuestionItem: React.FC<QuestionItemProps> = ({
    question, questionIndex, shouldShowHeader,
    quetionItemEditClick,
    quetionItemDeleteClick,
    questionItemAnswer,
    quesitonItemUploadFile,
    quesitonItemDeleteImage
}) => {
    const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const handleUpload = (file: UploadedFileResponse) => {
        console.log('Uploaded file:', file);
        quesitonItemUploadFile(question._id, file)
    };

    const onEditClick = () => {
        quetionItemEditClick(question._id)
    }
    const onDeleteClick = () => {
        quetionItemDeleteClick(question._id)
    }

    const handleDelete = async () => {
        quesitonItemDeleteImage(question._id)
    }

    const handleChange = (e: RadioChangeEvent) => {
        setSelectedAnswer(e.target.value);
        questionItemAnswer(question._id, e.target.value);
    };

    useEffect(() => {
        const correctAnswer = question.answers.find((answer) => answer.isCorrect);
        if (shouldShowHeader && correctAnswer) {
          setSelectedAnswer(correctAnswer._id);
        }
      }, [question.answers]);

    const renderHeader = () => {
        return (
            <div className={styles.itemHeader}>
                <span>#{questionIndex + 1}</span>
                <Button type="primary" style={{ width: 100, marginLeft: 20 }} onClick={handleOpenModal}>Chèn ảnh</Button>
                <div className={styles.actionButtons}>
                    <a onClick={() => onEditClick()}>
                        <EditOutlined />
                    </a>

                    <Popconfirm
                        title="Chắc chắn xoá câu hỏi này ?"
                        onConfirm={() => onDeleteClick()}
                        okText="Đồng ý"
                        cancelText="Đóng lại"
                        okType="danger"
                    >
                        <a style={{ marginLeft: 20 }}>
                            <DeleteOutlined />
                        </a>

                    </Popconfirm>
                </div>
            </div>
        )
    }

    return (
        <div>
            {shouldShowHeader && renderHeader()}
            {!shouldShowHeader && (<span className={styles.questionTitle}>#{questionIndex + 1}</span>)}

            {question.imagePath && (<div style={{ width: '300px' }}>
                <Image
                    src={question.imagePath}
                    alt="Uploaded"
                    style={{ width: '100%', height: 'auto' }} // Maintain aspect ratio
                />
            </div>)}

            {question.imagePath && shouldShowHeader && (

                <Popconfirm
                    title="Chắc chắn xoá ảnh này ?"
                    onConfirm={() => handleDelete()}
                    okText="Đồng ý"
                    cancelText="Đóng lại"
                    okType="danger"
                >
                    <Button
                        type="default"
                        size='small'
                        style={{
                            marginTop: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ff4d4f', // Red border color
                            background: 'transparent', // Transparent background
                            color: '#ff4d4f', // Red text color
                        }}
                    >
                        Delete Photo
                    </Button>
                </Popconfirm>
            )}

            <MathJaxComponent>
                {question.question}
            </MathJaxComponent>

            <UploadPhoto
                isOpen={modalOpen}
                onClose={handleCloseModal}
                onUpload={handleUpload}
            />

            <Radio.Group onChange={handleChange} value={selectedAnswer} style={{marginTop:10}}>
                {question.answers.map((answer, index) => (

                    <Radio key={answer._id} value={answer._id} style={{ display: 'flex', marginBottom: '8px',  }} disabled={shouldShowHeader}>
                        <MathJaxComponent>{answer.answer}</MathJaxComponent> 
                    </Radio>

                ))}
            </Radio.Group>

            <div className={styles.dividerBox}>    </div>
        </div>
    )
}

export default QuestionItem