import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as APIService from "../../services/APIService"
import UpdateAttendDetail from "../../types/attendDetail/UpdateAttendDetail";
import UpdatePaidForItems from "../../types/attendDetail/UpdatePaidForItems";

export const getAttendDetails = createAsyncThunk(
  "getAttendDetails",
  async (payload: string, { rejectWithValue }) => {
    try {
      const path = "attend_details"
      console.log("get attend details ")
      const response = await APIService.getData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const exportAttendDetails = createAsyncThunk(
  "exportAttendDetails",
  async (payload: string, { rejectWithValue }) => {
    try {
      const path = "attend_details/export"
      const response = await APIService.downloadFile(path, payload);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();

    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const attendDetailStat = createAsyncThunk(
  "attendDetailStat",
  async (payload: string, { rejectWithValue }) => {
    try {
      const path = "attend_details/stat"
      console.log("get attend details stat ")
      const response = await APIService.getData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAttendDetail = createAsyncThunk(
  "updateAttendDetail",
  async (payload: UpdateAttendDetail, { rejectWithValue }) => {
    try {
      const path = "attend_details/update"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAttendDetail = createAsyncThunk(
  "addAttendDetail",
  async (payload, { rejectWithValue }) => {
    try {
      const path = "attend_details/add"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeAttendDetail = createAsyncThunk(
  "removeAttendDetail",
  async (payload: { attendDetailId: number }, { rejectWithValue }) => {
    try {
      const path = "attend_details/remove"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAttendDetailPaid = createAsyncThunk(
  "updateAttendDetailPaid",
  async (payload: UpdatePaidForItems[], { rejectWithValue }) => {
    try {
      const path = "attend_details/update-paid"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Change checkbox data locally 

export const updateCheckBox = createAsyncThunk(
  "updateCheckBox",
  async (payload: { index: number, checkedValue: boolean }, { rejectWithValue }) => {
    try {
      return payload
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCheckBoxAll = createAsyncThunk(
  "updateCheckBoxAll",
  async (payload: { checkedValue: boolean }, { rejectWithValue }) => {
    try {
      return payload
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


//Add attend detail by providing class and date => create data
export const addManyAttendDetails = createAsyncThunk(
  "addManyAttendDetails",
  async (payload: { classIds: number[], attendDate: string }, { rejectWithValue }) => {
    try {
      const path = "attend_details/add-many"
      const response = await APIService.postData(path, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const attendDetailSlice = createSlice({
  name: "attendDetail",
  initialState: {
    attendDetails: [],
    currentPage: 1,
    numberOfPages: null,
    error: "",
    successMessage: "",
    loading: false,
    totalFee: 0,
    totalCount: 0, //Ca học hợp lệ
    totalRecords: 0  //Ca học chi tiết (kể cả nghỉ Nghỉ phép)
  },
  reducers: {
    setCurrentPage: (state, action) => {
      console.log(action)
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendDetails.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(getAttendDetails.fulfilled, (state, action) => {
        console.log("fulfilled")

        console.log("ATTEND DETAILS AFTER MAP")
        console.log(action.payload[1])

        state.loading = false;
        state.totalRecords = action.payload[1] //Dữ liệu trả về gồm cả số lượng records, và dữ liệu array các bản ghi.
        const items = action.payload[0].map((item, index) => {
          item["key"] = item.id
          item["attendId"] = item.id //Vẫn giống với key
          const pageSize = 30
          item["numberIndex"] = (state.currentPage - 1) * pageSize + index + 1
          item["name"] = item.member.name
          const dateStrings = item.attendDate.split("-");
          item["originalAttendDate"] = item.attendDate;
          item["attendDate"] = `${dateStrings[2]}/${dateStrings[1]}` + (item.shiftTime != undefined ? ` ${item.shiftTime}` : "")
          item["attendDateSkipTime"] = `${dateStrings[2]}/${dateStrings[1]}`
          item["tutorClass"] = item.tutorClass.name
          item["createdAt"] = item.createdAt.slice(0, 10)
          return item
        });
        state.attendDetails = items;
      })
      .addCase(getAttendDetails.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected")
        console.log(action.payload)
      })
      .addCase(attendDetailStat.pending, (state, action) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(attendDetailStat.fulfilled, (state, action) => {
        console.log("pending")
        state.loading = false;
        state.totalCount = action.payload.totalCount
        state.totalFee = action.payload.totalFee
      })
      .addCase(attendDetailStat.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected")
        console.log(action.payload)
      })
      .addCase(updateCheckBox.fulfilled, (state, action) => {
        console.log(action.payload)
        const { checkedValue, index } = action.payload
        state.attendDetails[index].paid = checkedValue
      })
      .addCase(updateCheckBoxAll.fulfilled, (state, action) => {
        const { checkedValue } = action.payload
        console.log(checkedValue)
        state.attendDetails = state.attendDetails.map((item) => {
          item.paid = checkedValue
          return item;
        })
      })
  },
});

export const { setCurrentPage } = attendDetailSlice.actions;

export default attendDetailSlice.reducer;
