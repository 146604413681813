import { DatePicker, Form, Select } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTutorClasses } from "app-redux/slices/tutorClass"
import { addManyAttendDetails } from "app-redux/slices/attendDetail"
import { getTimeTables } from "app-redux/slices/timeTable"
import { useSelector } from "react-redux";
import { showSuccessMessage, showErrorMessage, formatDate } from "helper/Helper"
import { AppDispatch, RootState } from 'app-redux/store';

import { getClassesForOneDay } from 'util/attendDetailListUtils';
import moment, { Moment } from 'moment';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

// eslint-disable-next-line react/prop-types
const AddManyAttendDetail = ({ form, setIsModalOpen, onFinishAddManyAttendDetails }) => {
    const { tutorClass, timeTable } = useSelector((state: RootState) => state)
    const [classList, setClassList] = useState([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const dispatch: AppDispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getTutorClasses());
        dispatch(getTimeTables());

    }, [])

    useEffect(() => {
        const tempClassList = tutorClass.tutorClasses.map((item, index) => {
            return {
                value: index,
                label: item.name
            }
        })
        setClassList(tempClassList)
    }, [tutorClass])

    const onFinish = (values) => {
        const temp = new Date(values.attendDate)
        const classIds = []
        for (const index of values.tutorClass) {
            classIds.push(tutorClass.tutorClasses[index].id)
        }
        const payload = {
            classIds: classIds,
            attendDate: formatDate(temp)
        }
        dispatch(addManyAttendDetails(payload)).unwrap().then((_) => {
            showSuccessMessage("Thêm thành công")
            onFinishAddManyAttendDetails();
        })
            .catch((e) => {
                showErrorMessage(e.message)
            })

        setIsModalOpen(false)
    }
    const onChangeSelect = (selectedItems: number[]) => {
        console.log(selectedItems)
        setSelectedItems(selectedItems);
    }

    const onDatePickerChange = (date: Moment | null, dateString: string) => {
        const dayInWeek = moment(date).format('dddd');
        const classesOfDay = getClassesForOneDay(timeTable.timeTables, dayInWeek)
        const tempArray: number[] = [];
    
        tutorClass.tutorClasses.forEach((class1, index) => {
            for(const class2 of classesOfDay){
                if (class1.id === class2.id){
                    tempArray.push(index);
                    break;
                }
            }
        })
    
        // eslint-disable-next-line
        form.setFieldsValue({ tutorClass: tempArray });
        setSelectedItems(tempArray);

    }

    return (
        <>
            <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                <Form.Item name="tutorClass" label="Chọn lớp" rules={[{ required: false }]}>
                    <Select
                        mode="multiple"
                        placeholder="Lọc theo lớp"
                        onChange={onChangeSelect}
                        style={{
                            width: '100%',
                        }}
                        filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
                        value={selectedItems}
                        options={classList}
                    />
                </Form.Item>
                <Form.Item name="attendDate" label="Chọn ngày" rules={[{ required: true }]}>
                    <DatePicker onChange={onDatePickerChange} />
                </Form.Item>

            </Form>
        </>
    )
}

export default AddManyAttendDetail
