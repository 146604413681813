import { DatePicker, Button, Input, Form, Select, message, Modal, FormInstance } from 'antd';
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { getTutorClasses, addMemberToClass } from "app-redux/slices/tutorClass"
import { addMember } from "app-redux/slices/member"
import { getMembers } from "app-redux/slices/member"
import { useSelector } from "react-redux";
import { showSuccessMessage, showErrorMessage, formatDate } from "helper/Helper"
import { AppDispatch, RootState } from 'app-redux/store';
import styles from './AddMemberToClass.module.css'
import { find } from '@reduxjs/toolkit/dist/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const onChange = (date, dateString) => {
  console.log(date, dateString);
};

interface MyComponentProps {
  form: FormInstance;
  onFinishAddMemberToClass;
  classId: number
}

// eslint-disable-next-line react/prop-types
const AddMemberToClass: React.FC<MyComponentProps> = ({ form, onFinishAddMemberToClass, classId }) => {
  const { member, tutorClass } = useSelector((state: RootState) => state)
  const [classList, setClassList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const [formAddMember] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getMembers(""));
    dispatch(getTutorClasses());

  }, [])

  useEffect(() => {
    const tempClassList = tutorClass.tutorClasses.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    const currentClassIndex = tutorClass.tutorClasses.findIndex(item => item.id === classId);
    form.setFieldsValue({tutorClass: currentClassIndex});
    
    setClassList(tempClassList)
  }, [tutorClass])

  useEffect(() => {
    const tempList = member.members.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    setMemberList(tempList)
  }, [member])

  const onFinish = (values) => {
    const temp = new Date(values.startDate)
    const payload = {
      classId: tutorClass.tutorClasses[values.tutorClass].id,
      memberId: member.members[values.member].id,
      startDate: formatDate(temp),
      endDate: "2030-10-01",
      discount: 0
    }

    dispatch(addMemberToClass(payload)).unwrap().then((_) => {
      showSuccessMessage("Thêm thành công")
      onFinishAddMemberToClass();
    })
      .catch((e) => {
        console.log(e)
        showErrorMessage(e.message)
      })

  };

  const onFinishAddMemberClick = async (values) => {
    const numberOfMembers = member.members.length;

    const existed = member.members.some(member => member.name === values.name)
    let shouldAdd = true;
    if (existed){
      shouldAdd = window.confirm(`Đã có học sinh với tên ${values.name} trong hệ thống, bạn có muốn tạo thêm học sinh trùng tên ?`);
    }
    if (!shouldAdd){
      console.log("duplicate name, stop adding user")
      return
    }
    try{
      await dispatch(addMember(values)).unwrap();
      await dispatch(getMembers("")).unwrap();
      showSuccessMessage("Tạo mới thành công")
      form.setFieldsValue({ member: numberOfMembers }) //select the last item in the select box programtically, why not numberOfMembers: because we have added one new member
      setIsModal2Open(false)
    }
    catch(error){
      showErrorMessage(error.message)
    }   
  }

  const handleCancelAddMember = () => {
    setIsModal2Open(false)
  }

  const showAddMember = () => {
    setIsModal2Open(true)
  }

  return (
    <div>
      <Modal title={"Thêm mới học viên"} open={isModal2Open}
        onOk={formAddMember.submit}
        onCancel={handleCancelAddMember}>

        <div className={styles.addMemberForm}>
          <Form {...layout} form={formAddMember} name="control-hooks" onFinish={onFinishAddMemberClick}>
            <Form.Item name="name" label="Tên học viên" rules={[{ required: true, message: "Tên học viên trống" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="parent" label="Phụ huynh" rules={[{ required: true, message: "Tên phụ huynh trống" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="mobile" label="Số ĐT Phụ huynh" rules={[{ required: true, message: "Số điện thoại trống" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="note" label="Ghi chú" rules={[{ required: false }]}>
              <Input />
            </Form.Item>
          </Form>
        </div>
        <span style={{ fontStyle: "italic" }}>Dấu (<span style={{ color: "red" }}>*</span>) là bắt buộc nhập dữ liệu </span>
      </Modal>


      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="tutorClass" label="Chọn lớp" rules={[{ required: true }]}>
          <Select
            disabled={true}
            style={{ width: 200 }}
            placeholder="Lọc theo lớp"
            options={classList}
          />

        </Form.Item>
        <Form.Item name="member" label="Chọn học sinh" rules={[{ required: true, message: "Vui lòng chọn học sinh" }]}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Tên học sinh"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={memberList}
          />
        </Form.Item>
        <Form.Item name="startDate" label="Ngày bắt đầu" rules={[{ required: true, message:"Chọn ngày băt đầu học" }]}>
          <DatePicker onChange={onChange} placeholder='Chọn ngày' />
        </Form.Item>

      </Form>
      <div className={styles.addNewMember}> Hoặc <span className={styles.addNewMemberLink} onClick={showAddMember}>Tạo mới</span> </div>
    </div>
  )
}

export default AddMemberToClass