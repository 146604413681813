import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as APIService from "../../services/APIService"
import { User } from '../../types/user/User';

interface UserState {
    user: null | User;
    loading: boolean;
    error: null | string;
}

const initialState: UserState = {
    user: null,
    loading: false,
    error: null,
};

export const getProfile = createAsyncThunk(
    'users/profile',
    async (_, thunkAPI) => {
        try {
            const path = "users/profile"
            const response = await APIService.getData(path);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateProfile = createAsyncThunk(
    'users/updateSetting',
    async (payload: {
        prefixTextToSend?: string,
        bankList?: string[],
        latePayNumberDayWarning?: number
    }, thunkAPI) => {
        try {
            const path = "users/update-setting"
            const response = await APIService.postData(path, payload);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Create a slice for the user state and reducers
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getProfile.fulfilled, (state, action: PayloadAction<User>) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default userSlice.reducer;
