import { useNavigate, useParams } from 'react-router-dom';
import React, { Component } from 'react';
import { Button, Popconfirm, Row, Space, Table } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import styles from "./ExamResult.module.css"
import { useSelector } from 'react-redux';
import { RootState } from 'app-redux/store';
import { useGetExamDetailQuery, useGetExamResultQuery } from 'app-redux/slices/exam';
import { formatDate } from 'helper/Helper';


const ExamResult: React.FC = () => {
    const { examId } = useParams()
    const navigate = useNavigate()
    const { auth } = useSelector((state: RootState) => state)
    const { data, error, isLoading, refetch } = useGetExamResultQuery(examId)
    const { data: examDetail, error: examDetailError, isLoading: examDetailLoading, refetch:fetchDetail } = useGetExamDetailQuery(examId)

    if (isLoading || examDetailLoading) return <div>Loading...</div>;
    if (error || examDetailError) return <div>Error: {"some error"}</div>;


    const examResults = data || [];
    const dataSource = examResults.map((aExam, index) => {
        const createdAt = new Date(aExam.createdAt)
        
        return {
            key: aExam._id,
            numberIndex: index + 1,
            name: aExam.member.name,
            answer: `${aExam.correctCount}/${examDetail.questions.length}`,
            point: aExam.correctCount * 10,
            createdAt: formatDate(createdAt, true)
        }
    })

    const columns = [
        {
            title: '#',
            dataIndex: 'numberIndex',
            width: 60
        },
        {
            title: 'Tên học viên',
            dataIndex: 'name',
            width: 250
        },
        {
            title: 'Trả lời đúng',
            dataIndex: 'answer',
            width: 200
        },
        {
            title: 'Số điểm',
            dataIndex: 'point'
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            width: 150
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 120,
            render: (_, record, index) => (
                <Space size="middle">
                    <a onClick={() => onDetailClick(index)}>
                        <EyeOutlined />
                    </a>
                </Space>
            ),
        },
    ];

    const onEditQuestion = () => {
        navigate(`/exam/questions/${examId}`);
    }

    const onAnswerExam = () => {
        navigate(`/exam/answer-exam/${examId}`);
    }

    const onChangePage = (pagination) => {

    }

    const onDeleteClick = (index) => {

    }

    const onEditClick = (index) => {

    }

    const onDetailClick = (index) => {

    }

    const onAddClick = (index) => {

    }

    return (
        <div>
            <Row justify="end" style={{ paddingBottom: 10 }}>
                {!auth.isMemberType && (
                    <Space>
                        <Button type="primary" onClick={onEditQuestion}>Chỉnh sửa câu hỏi</Button>
                    </Space>
                )}
                {auth.isMemberType && (
                    <Space>
                        <Button type="primary" onClick={onAnswerExam}>Làm bài kiểm tra</Button>
                    </Space>
                )}
            </Row>

            <div className={styles.examName}>
                <span> {examDetail.name}, {examDetail.desc} </span>
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 1000 }} size='small' pagination={{ pageSize: 20, total: dataSource.length }} onChange={onChangePage} />


        </div>
    )
}

export default ExamResult