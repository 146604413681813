import React, { useEffect, useState } from 'react';
import { Modal, message} from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { Select, Button } from 'antd';

import { TimeTable } from 'types/timeTable/TimeTable';
import { TimeTableItem } from 'types/timeTable/TimeTableItem';
import { getTutorClasses } from "app-redux/slices/tutorClass"

import styles from './AddTimeTable.module.css';
import { AppDispatch, RootState } from 'app-redux/store';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

interface BodyData {
    startDate: string;
    endDate: string;
    classId: number; 
    timeTable: {dayInWeek:string, time:number}[];
  }

interface AddTimeTableProps {
    visible: boolean;
    onCancel: () => void;
    timeTable: TimeTable
    popupTitle: string
    onPopupFinish: (bodyData: BodyData) => void
}

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const generateTimeOptions = (): string[] => {
    const timeOptions: string[] = [];
    for (let hour = 6; hour <= 20; hour++) {
        for (let minute = 0; minute < 60; minute += 30) { //Step 30 minutes (0.5 hour)
            const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
            const formattedMinute = minute === 0 ? '00' : `${minute}`;
            timeOptions.push(`${formattedHour}:${formattedMinute}`);
        }
    }
    return timeOptions;
};

const AddTimeTable: React.FC<AddTimeTableProps> = ({ visible, onCancel, timeTable, popupTitle, onPopupFinish }) => {
    const [workingTimeTable, setWorkingTimeTable] = useState<TimeTableItem[]>([{ dayInWeek: 'Mon', time: 0 }]);
    const [classList, setClassList] = useState([])
    const [classId, setClassId] = useState<number | undefined>()
    const [selectedClassName, setSelectedClassName] = useState<string>()
    const { tutorClass } = useSelector((state: RootState) => state)

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getTutorClasses());
    }, [])

    useEffect(() => {
        const tempClassList = tutorClass.tutorClasses.map((item, index) => {
            return {
                value: index,
                label: item.name
            }
        })
        setClassList(tempClassList)
    }, [tutorClass])

    useEffect(() => {
        if (timeTable !== undefined) {
            const tempData = [...timeTable.timeTable];
            setWorkingTimeTable(tempData);
            setSelectedClassName(timeTable.tutorClass.name)
            setClassId(timeTable.tutorClass.id)
        }
    }, [timeTable])

    const handleDayChange = (value: string, index: number) => {
        const updatedTimeTable = workingTimeTable.map((item, i) => {
            if (i === index) {
                // Create a new object with the same properties as the original
                return { ...item, dayInWeek: value };
            }
            return item; // For other items, keep them unchanged
        });
        setWorkingTimeTable(updatedTimeTable);

    };

    const handleTimeChange = (value: string, index: number) => {
        const updatedTimeTable = workingTimeTable.map((item, i) => {
            if (i === index) {
                // Create a new object with the same properties as the original
                return { ...item, formattedTime: value };
            }
            return item; // For other items, keep them unchanged
        });

        setWorkingTimeTable(updatedTimeTable);
    };

    const handleAddNewItem = () => {
        setWorkingTimeTable([...workingTimeTable, { dayInWeek: 'Mon', time: 0 }]);
    };

    const handleRemoveItem = (index: number) => {
        const updatedTimeTable = [...workingTimeTable];
        updatedTimeTable.splice(index, 1);
        setWorkingTimeTable(updatedTimeTable);
    }

    const onOk = () => {
        const tempArray = [];
        for (const eachItem of workingTimeTable) {
            const splitted = eachItem.formattedTime.split(":");
            if (splitted.length === 2) {
                const hourPart = Number(splitted[0]);
                const minutePart = Number(splitted[1]);
                const time = hourPart + minutePart / 60;
                tempArray.push({ dayInWeek: eachItem.dayInWeek, time: time })
            }
        }

        if (classId === undefined){
            message.error("Chưa chọn lớp");
            
            return;
        }

        const bodyData = {
            startDate: "2022-10-15", //Hardcode, will refactor
            endDate: "2022-11-20", //Hardcode, will refactor
            classId: classId,
            timeTable: tempArray
          }

        onPopupFinish(bodyData);
    }

    const onChangeSelect = (value) => {
        console.log(value)
        setClassId(tutorClass.tutorClasses[value].id)
        setSelectedClassName(tutorClass.tutorClasses[value].name)
      }

    return (
        <Modal
            title={popupTitle}
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
        >
            <div className={styles.timeTableContainer}>
                <Select
                    showSearch
                    value={selectedClassName}
                    disabled={popupTitle.includes("Sửa")}
                    style={{ width: 300, marginBottom: 20 }}
                    onChange={onChangeSelect}
                    placeholder="Lọc theo lớp"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={classList}
                />

                {
                    workingTimeTable !== undefined ? workingTimeTable.map((item, index) => {
                        return (
                            <div key={index} className={styles.timeTableItem}>
                                <div className={styles.selectContainer}>
                                    <Select
                                        placeholder="Chọn ngày"
                                        value={item.dayInWeek}
                                        onChange={(value) => handleDayChange(value, index)}
                                    >
                                        {daysOfWeek.map((day) => (
                                            <Option key={day} value={day}>
                                                {day}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className={styles.selectContainer}>
                                    <Select
                                        placeholder="Chọn giờ"
                                        value={item.formattedTime}
                                        onChange={(value) => handleTimeChange(value, index)}
                                    >
                                        {generateTimeOptions().map((timeOption) => (
                                            <Option key={timeOption} value={timeOption}>
                                                {timeOption}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <Button shape="circle" icon={<CloseOutlined />} onClick={() => handleRemoveItem(index)}>

                                </Button>
                            </div>
                        )
                    }) : null
                }

                <Button style={{ marginTop: 10, width: 300 }} type="primary" onClick={handleAddNewItem}>
                    Thêm mới lịch dạy
                </Button>
            </div>

        </Modal>
    )

}

export default AddTimeTable;