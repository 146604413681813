import { Space, Table, Row, Modal, DatePicker, Form, Button, Select, Tag, Input, InputNumber, Popconfirm } from 'antd';
import { Checkbox } from 'antd';
import { connect, useDispatch } from "react-redux";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SettingOutlined } from '@ant-design/icons';

import { addMember, getMembers } from "app-redux/slices/member"
import { getTutorClasses } from "app-redux/slices/tutorClass"
import { getAttendDetails, attendDetailStat, updateAttendDetail, removeAttendDetail, exportAttendDetails, setCurrentPage, updateCheckBox, updateCheckBoxAll, updateAttendDetailPaid } from "app-redux/slices/attendDetail"
import { showSuccessMessage, showErrorMessage, formatDate, calculateDaysDifference } from "helper/Helper"
import { AppDispatch, RootState } from 'app-redux/store';

import AddAttendDetail from './AddAttendDetail.component';
import AddManyAttendDetail from './AddManyAttendDetail.component';
import UpdateAttendDetail from 'types/attendDetail/UpdateAttendDetail';

import styles from './ReportAttendDetail.module.css';
import UserSetting from '../../userSetting/UserSetting';


const { TextArea } = Input;
const { RangePicker } = DatePicker;
const pageSize = 30
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const PaidType = {
  All: "All",
  NotPaid: "NotPaid"
}

const ReportAttendDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isModal3Open, setIsModal3Open] = useState(false);
  const [isModal4Open, setIsModal4Open] = useState(false);

  const [classList, setClassList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const today = new Date()
  const tempDate = new Date(today.valueOf())
  const dateOffset = (24 * 60 * 60 * 1000) * 30; //30 days
  tempDate.setTime(tempDate.getTime() - dateOffset);
  const newStartDate = new Date(tempDate)

  const [startDate, setStartDate] = useState(formatDate(newStartDate))
  const [endDate, setEndDate] = useState(formatDate(today))
  const [memberId, setMemberId] = useState()
  const [tutorClassId, setTutorClassId] = useState()
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [attendDetailId, setAttendDetailId] = useState()
  const [summaryText, setSummaryText] = useState("")
  const [checkAll, setCheckAll] = useState(false)
  const [paidType, setPaidType] = useState(PaidType.All)
  const [showFullDetailToCopy, setShowFullDetailToCopy] = useState(false);
  const [userSettingVisible, setUserSettingVisible] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { user, member, tutorClass, attendDetail } = useSelector((state: RootState) => state)

  const dateFormat = 'YYYY-MM-DD';

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const [formTitle] = useState("Sửa ca học")

  const buildSummaryText = () => {
    if (memberId != undefined) {
      //Get text message
      let summaryName = ""
      const allAttendDetails = []
      for (const item of attendDetail.attendDetails) {
        if (summaryName == "") {
          summaryName = item.member.name
        }
        //search class in list
        const resultClass = allAttendDetails.filter(arrayItem => arrayItem.tutorClass == item.tutorClass)
        const attendDetailsBySameDate = attendDetail.attendDetails.filter(arrayItem => arrayItem.attendDateSkipTime == item.attendDateSkipTime)
        
        let dateText = ""
        if (attendDetailsBySameDate.length > 1) { //Vì 1 ngày học nhiều ca lấy dữ liệu có time để phân biệt
          dateText = item.type == 4 ? `${item.attendDate} - Học thêm` : item.type == 2 ? `${item.attendDate} - Học bù` : item.attendDate
        }
        else {
          dateText = item.type == 4 ? `${item.attendDateSkipTime} - Học thêm` : item.type == 2 ? `${item.attendDateSkipTime} - Học bù` : item.attendDateSkipTime
        }

        if (resultClass.length == 0) { //Not existed. Chưa có key class này thì tạo mới 1 object và đẩy ngày học vào
          if (item.type == 0 || item.type == 2 || item.type == 4) {
            allAttendDetails.push({ tutorClass: item.tutorClass, details: [dateText] })
          }
        }
        else { //Đã có dữ liệu class rồi thì đẩy thêm ngày học vào.
          if (item.type == 0 || item.type == 2 || item.type == 4) {
            resultClass[0].details.push(dateText)
          }
        }
      }
      let allAttendDetailsText = ""
      for (const eachClass of allAttendDetails) {
        allAttendDetailsText += `
Lớp ${eachClass.tutorClass}: ${eachClass.details.join(', ')}`
      }

      //Add 1 new line
      allAttendDetailsText = `${allAttendDetailsText}

      `;
      if (!showFullDetailToCopy) {
        allAttendDetailsText = '';
      }

      const totalCount = attendDetail.totalCount
      const totalFee = attendDetail.totalFee.toLocaleString(undefined, { maximumFractionDigits: 2 })
      const startDateStrings = startDate.split("-");
      const endDateStrings = endDate.split("-");
      const bankInfo = user.user.userSetting.bankList.length > 0 ? user.user.userSetting.bankList[0] : "MBBANK * 8120128989999 * DO XUAN THANH"
      setSummaryText(`${user.user.userSetting.prefixTextToSend} ${summaryName}
${allAttendDetailsText}
Tổng CA học: ${totalCount}; Tổng tiền: ${totalFee}

Chuyển khoản: ${bankInfo}
Nội dung: 
${summaryName} tu ${startDateStrings[2]}thg${startDateStrings[1]} den ${endDateStrings[2]}thg${endDateStrings[1]}`)
    }
  }

  useEffect(() => {
    dispatch(getMembers(""));
    dispatch(getTutorClasses());
    loadData()
  }, [])

  useEffect(() => {
    buildSummaryText();
  }, [attendDetail, showFullDetailToCopy])

  useEffect(() => {
    const tempList = member.members.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    setMemberList(tempList)
  }, [member])

  useEffect(() => {
    const tempClassList = tutorClass.tutorClasses.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    setClassList(tempClassList)
  }, [tutorClass])

  useEffect(() => {
    loadData()
  }, [memberId, tutorClassId, startDate, endDate, paidType, sortColumn, sortOrder])

  useEffect(() => {
    loadData()
  }, [attendDetail.currentPage])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onAddClick = () => {
    setIsModal2Open(true)
  }

  const onAddManyClick = () => {
    setIsModal3Open(true)
  }

  const onGetTextClick = () => {
    setIsModal4Open(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModal2Open(false);
    setIsModal3Open(false);
    setIsModal4Open(false);
  };

  const onFinish = (values) => {
    const payload: UpdateAttendDetail = {
      attendDetailId: attendDetailId,
      type: Number(values.type),
      fee: values.fee
    }
    dispatch(updateAttendDetail(payload)).unwrap().then((_) => {
      loadData()
      showSuccessMessage("Cập nhật thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      })
    setIsModalOpen(false);
  };

  const onFinishAddManyAttendDetails = () => {
    loadData();
  }

  const onEditClick = (attendId) => {
    const tempList = attendDetail.attendDetails.filter(item => item.id == attendId)
    if (tempList.length > 0) {
      const attendDetailObj = tempList[0]
      setAttendDetailId(attendId)
      form.setFieldsValue({ fee: attendDetailObj.fee, type: attendDetailObj.type })
      showModal()
    }
  }

  const onCopyText = () => {
    setIsModal4Open(false)
    showSuccessMessage("Đã copy vào cliboard")
    navigator.clipboard.writeText(summaryText);
  }

  const onDeleteClick = (attendId) => {
    const payload = {
      attendDetailId: attendId
    }
    dispatch(removeAttendDetail(payload)).unwrap().then((_) => {
      loadData()
      showSuccessMessage("Xoá thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      })
  }

  const onExportClick = () => {
    let params = `startDate=${startDate}&endDate=${endDate}&take=100000` //Lấy hết dữ liệu khi export chứ ko phân trang
    if (memberId != undefined) {
      params += `&memberId=${memberId}`
    }
    if (tutorClassId != undefined) {
      params += `&classId=${tutorClassId}`
    }
    dispatch(exportAttendDetails(params));
  }

  const onSavePaidClick = () => {
    const params = []
    for (const item of attendDetail.attendDetails) {
      params.push({ attendDetailId: item.id, value: item.paid })
    }

    dispatch(updateAttendDetailPaid(params)).unwrap().then((_) => {
      showSuccessMessage("Cập nhật thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      })
  }

  const loadData = () => {
    let params = `startDate=${startDate}&endDate=${endDate}&page=${attendDetail.currentPage}&take=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`
    if (memberId != undefined) {
      params += `&memberId=${memberId}`
    }
    if (tutorClassId != undefined) {
      params += `&classId=${tutorClassId}`
    }
    params += `&paidType=${paidType}`

    dispatch(getAttendDetails(params));
    dispatch(attendDetailStat(params));
  }

  const onChangeSelectClass = (value) => {
    const classId = tutorClass.tutorClasses[value].id
    setTutorClassId(classId)
    const params = `classId=${classId}`
    dispatch(getMembers(params));
  }

  const onChangeSelect = (value) => {
    const memberId = member.members[value].id
    setMemberId(memberId)
  }

  const onChangeSelectPaidType = (value) => {
    setPaidType(value)
  }

  const onChangeTable = (pagination, filter, sorter) => {
      setSortColumn(sorter.field);
      setSortOrder(sorter.order);
      dispatch(setCurrentPage(pagination.current))
  }

  const onChangeCheckBox = (index, e) => {
    const checkedValue = e.target.checked
    const checkedObj = { index: index, checkedValue }
    dispatch(updateCheckBox(checkedObj))
  };

  const onChangeCheckBoxAll = (e) => {
    const checkedValue = e.target.checked
    setCheckAll(checkedValue)
    dispatch(updateCheckBoxAll({ checkedValue }))
  };

  const onClearSelectClass = () => {
    setTutorClassId(null);
    dispatch(getMembers(""));
  }

  const onClearSelectMember = () => {
    setMemberId(null);
  }

  const attendDetailStatus = (_, { type }) => {
    const labelColor = type == 0 ? "green" : type == 2 ? "blue" : type == 4 ? "cyan" : "volcano"
    let labelText
    switch (type) {
      case 1: labelText = "Nghỉ phép"; break
      case 2: labelText = "Học bù"; break
      case 3: labelText = "Đã xoá"; break
      case 4: labelText = "Học thêm"; break
      default: labelText = "Default"; break
    }
    return (
      <>
        <Tag color={labelColor} key={type}>
          {labelText}
        </Tag>
      </>)
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'numberIndex',
    },
    {
      title: "Đã thu",
      dataIndex: 'paid',
      render: (_, record, index) => (<>  <Checkbox checked={record.paid} onChange={(e) => onChangeCheckBox(index, e)}></Checkbox></>)
    },
    {
      title: 'Học Sinh',
      dataIndex: 'name',
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: 'Lớp',
      dataIndex: 'tutorClass',
    },
    {
      title: 'Ngày học',
      dataIndex: 'attendDate',
      sorter: (a, b) => a.attendDate - b.attendDate,
    },
    {
      title: 'Học phí',
      dataIndex: 'fee',
      render: (_, { fee }) => (<>{fee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>)
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      render: attendDetailStatus
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => onEditClick(record.attendId)}>Sửa</a>
          <Popconfirm
            title="Chắc chắn xoá dữ liệu này?"
            onConfirm={() => onDeleteClick(record.attendId)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Xoá</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getRowClassName = (record) => {
    const daysDifference = calculateDaysDifference(record.originalAttendDate);
    console.log(daysDifference);
    // Add your condition here
    
    if (daysDifference > 40 && record.paid === false) {
      return styles.warningRow;
    }
    return '';
  };

  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0])
    setEndDate(dateStrings[1])
  };

  const handleShowFullDetailCheckbox = (e) => {
    const checkedValue = e.target.checked
    setShowFullDetailToCopy(checkedValue);
  }

  const handleSettingClick = () => {
    setUserSettingVisible(true);

  }

  const handleCancelUserSetting = () => {
    setUserSettingVisible(false)
  }

  return (
    <div className={styles['report-container']}>
      <UserSetting visible={userSettingVisible} onCancel={handleCancelUserSetting} />
      <Modal title={"Thêm ca học"} open={isModal2Open} onOk={form2.submit} onCancel={handleCancel}>
        <AddAttendDetail form={form2} setIsModalOpen={setIsModal2Open} ></AddAttendDetail>
      </Modal>
      <Modal title={"Kết quả học"} open={isModal4Open} onOk={onCopyText} onCancel={handleCancel} okText="Copy">
        <div className={styles.copyTextSetting}>
          <div>
            <Checkbox className={styles.showDetailCheckbox} checked={showFullDetailToCopy} onChange={handleShowFullDetailCheckbox}></Checkbox>
            <span className={styles.smallNoteText}>Hiển thị chi tiết buổi học</span>
          </div>
          <Button type="link" onClick={handleSettingClick} icon={<SettingOutlined />} style={{ display: 'flex', alignItems: 'center' }}>
            Cài đặt cá nhân
          </Button>
        </div>
        <TextArea style={{ marginTop: 30 }} rows={4} value={summaryText} />
      </Modal>

      <Modal title={"Tạo nhiều ca học"} open={isModal3Open} onOk={form3.submit} onCancel={handleCancel}>
        <AddManyAttendDetail form={form3} setIsModalOpen={setIsModal3Open} onFinishAddManyAttendDetails = {onFinishAddManyAttendDetails} ></AddManyAttendDetail>
      </Modal>

      <Modal title={formTitle} open={isModalOpen} onOk={form.submit} onCancel={handleCancel}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="fee" label="Học phí" rules={[{ required: true }]}>
            <InputNumber min={20000} />
          </Form.Item>
          <Form.Item name="type" label="Trạng thái" rules={[{ required: true }]}>
            <Select

              style={{ width: 120 }}
              options={[
                {
                  value: '0',
                  label: 'Default',
                },
                {
                  value: '1',
                  label: 'Nghỉ phép',
                },
                {
                  value: '2',
                  label: 'Học bù',
                },
                {
                  value: '4',
                  label: 'Học thêm',
                },
                {
                  value: '3',
                  label: 'Đã xoá',
                }
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
      <h1 className={styles.mainTitle}> Kết quả học chi tiết </h1>
      <Space>
        <h3>Số ca học: <span className={styles.totalShiftNumber}>{attendDetail.totalCount}</span></h3>
        <h3>Số tiền: <span className={styles.totalValue}>{attendDetail.totalFee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span> </h3>
      </Space>
      <div className={styles['main-content']}>

        <Select
          showSearch
          style={{ width: 200 }}
          allowClear={true}
          onClear={onClearSelectClass}
          onChange={onChangeSelectClass}
          placeholder="Lọc theo lớp"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={classList}
        />
        <Select
          showSearch
          style={{ marginLeft: 5, width: 200 }}
          allowClear={true}
          onClear={onClearSelectMember}
          onChange={onChangeSelect}
          placeholder="Lọc theo học sinh"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={memberList}
        />
        <Select
          showSearch
          style={{ marginLeft: 5, width: 200 }}
          onChange={onChangeSelectPaidType}
          defaultValue={PaidType.All}
          options={[
            { value: PaidType.All, label: 'Tất cả' },
            { value: PaidType.NotPaid, label: 'Chưa thu' }
          ]}
        />

        <RangePicker
          style={{ marginLeft: 5 }}
          defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
          onChange={onChange} />
        <Button className={styles['getText-button']} type="primary" disabled={memberId == undefined} onClick={onGetTextClick}>Lấy text</Button>
        <Row justify="end">

          <div style={{ "padding": "20px 5px" }}>
            <Checkbox checked={checkAll} onChange={onChangeCheckBoxAll}>Chọn tất cả</Checkbox>
            <Button className={styles['task-button']} type="primary" onClick={onSavePaidClick}>Lưu lại</Button>
            <Button className={styles['task-button']} type="primary" onClick={onAddClick}>Thêm ca học</Button>
            <Button className={styles['task-button']} type="primary" onClick={onAddManyClick}>Tự động tạo ca học</Button>
            <Button className={styles['task-button']} type="primary" onClick={onExportClick}>Export</Button>

          </div>
        </Row>
        <div className={styles['table-container']}>
          <Table
            className={styles['main-table']}
            rowClassName={getRowClassName}
            columns={columns}
            sticky={true}
            dataSource={attendDetail.attendDetails}
            size='small'
            pagination={{ pageSize: pageSize, total: Math.ceil(attendDetail.totalRecords) }}
            onChange={onChangeTable} />
        </div>
      </div>
    </div>
  )
}

export default connect(null, { addMember })(ReportAttendDetail);