import timeTable from "../app-redux/slices/timeTable";
import { TimeTable } from "../types/timeTable/TimeTable";
import { TutorClass } from "../types/tutorClass/TutorClass";

export const getClassesForOneDay = (timeTables:TimeTable[], dayInWeek:string) => {
    
    const tutorClasses: TutorClass[] = [];
    const shortDayInWeek = dayInWeek.substring(0,3);

    timeTables.forEach((timeTable) => {
        const hasDayInWeek = timeTable.timeTable.some((item) => item.dayInWeek === shortDayInWeek);
        
        if (hasDayInWeek) {
            tutorClasses.push(timeTable.tutorClass);
        }
    });
    return tutorClasses;
}