import React, { useState } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { UploadFile } from 'antd/es/upload/interface';
import { UploadedFileResponse } from 'types/file/UploadFileResponse';
import { useImportQuestionsMutation } from 'app-redux/slices/exam';

interface UploadModalProps {
    isOpen: boolean;
    examId: string, 
    onClose: () => void;
    onUpload: () => void;
}

const UploadFileModal: React.FC<UploadModalProps> = ({ isOpen, examId, onClose, onUpload }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [importQuestions] = useImportQuestionsMutation();

    const handleChange = (info) => {
        console.log("abelhoang")
        console.log(info)

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            onUpload();
            onClose();
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        setFileList(info.fileList);
    };

    const beforeUpload = (file: UploadFile) => {
        return true
    };

    const handleUploadFile = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('examId', examId);

        try {
            const responseData = await importQuestions(formData).unwrap();
            console.log("upload success")
            onSuccess(responseData)
        } catch (error) {
            console.log("upload fail")
            onError(new Error('Upload failed'))
        }
    }

    return (
        <Modal
            title="Upload file"
            open={isOpen}
            onCancel={onClose}
            footer={null}
        >
            <div style={{ marginBottom: '20px' }}>
                <a
                    href={`${process.env.PUBLIC_URL}/exam-question-template.xlsx`} // Path to the Excel file in public folder
                    download // This will trigger a download
                    style={{ fontSize: '16px', color: '#1890ff', textDecoration: 'underline' }}
                >
                    Tải file mẫu
                </a>
            </div>

            <Upload
                fileList={fileList}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                showUploadList={false}
                customRequest={handleUploadFile}

            >
                <div style={{ border: '2px dashed #d9d9d9', padding: '20px', textAlign: 'center' }}>
                    <UploadOutlined style={{ fontSize: 24 }} />
                    <p>Bấm vào đây hoặc kéo file vào khu vực này để upload</p>
                </div>
            </Upload>
            {fileList.length > 0 && (
                <div style={{ marginTop: 16 }}>
                    <strong>File đã chọn:</strong> {fileList[0].name}
                </div>
            )}
            <div style={{ marginTop: 16 }}>
                <Button type="primary" onClick={() => fileList.length > 0}>
                    Upload
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={onClose}>
                    Huỷ
                </Button>
            </div>
        </Modal>
    );
};

export default UploadFileModal;
