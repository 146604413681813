import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as APIService from "../../services/APIService"
import { User } from '../../types/user/User';
import { UserLoginResponse } from '../../types/user/UserLoginResponse';
import { Member } from 'types/member/Member';

interface AuthState {
    user: null | User;
    member: null | Member;
    isMemberType: boolean 
    loading: boolean;
    error: null | string;
}

const initialState: AuthState = {
    user: JSON.parse(localStorage.getItem('user')) || null,
    member: JSON.parse(localStorage.getItem('member')) || null,
    isMemberType: JSON.parse(localStorage.getItem('member')) ? true:false,
    loading: false,
    error: null,
};

export const loginUser = createAsyncThunk(
    'auth/login',
    async (payload: { email: string, password: string }, thunkAPI) => {
        try {
            const path = "auth/login"
            const response = await APIService.postData(path, {email:payload.email, password:payload.password});
            return response.data; // Assuming the API returns user data upon successful login
        } catch (error) {
            console.error(error.response.data);
            return thunkAPI.rejectWithValue(error.response.data);
        } 
    }
);


export const loginMember = createAsyncThunk(
    'auth/login-member',
    async (payload: { code: string, password: string }, thunkAPI) => {
        try {
            const path = "auth/login-member"
            const response = await APIService.postData(path, {code:payload.code, password:payload.password});
            return response.data; // Assuming the API returns user data upon successful login
        } catch (error) {
            console.error(error.response.data);
            return thunkAPI.rejectWithValue(error.response.data);
        }    
    }
);

export const changePasswordAsync = createAsyncThunk(
    'auth/changePass',
    async ({ password, newPassword }: { password: string, newPassword: string }, thunkAPI) => {
        try {
            const path = "auth/change-password"
            const response = await APIService.postData(path, { password, newPassword});
            return response.data; 
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/register',
    async ({ email, password, name, phone }: { email: string; password: string, name:string, phone:string }, thunkAPI) => {
        try {
            const path = "auth/register"
            const response = await APIService.postData(path, {email, password, name, phone});
            return response.data; 
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action:PayloadAction<UserLoginResponse>) => {
                state.loading = false;
                localStorage.setItem("accessToken", action.payload.accessToken);

                state.user = action.payload.newUser;
                localStorage.setItem("user", JSON.stringify(action.payload.newUser));
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(loginMember.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginMember.fulfilled, (state, action:PayloadAction<UserLoginResponse>) => {
                state.loading = false;
                localStorage.setItem("accessToken", action.payload.accessToken);

                state.member = action.payload.newMember;
                localStorage.setItem("member", JSON.stringify(action.payload.newMember));
            })
            .addCase(loginMember.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(changePasswordAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(changePasswordAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(changePasswordAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
        
    },
});

export default authSlice.reducer;
