import React, { useEffect } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const MathJaxComponent: React.FC<{ children: string }> = ({ children }) => {
    return (
      <MathJaxContext>
        <MathJax>{children}</MathJax>
      </MathJaxContext>
    );
  };

export default MathJaxComponent;
