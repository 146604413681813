import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Dropdown, Avatar, MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './MainLayout.module.scss';

import ChangePassword from '../pages/auth/changePassword/ChangePassword';
import { useSelector } from 'react-redux';
import { RootState } from 'app-redux/store';

const { Header, Content, Footer, Sider } = Layout;


function getItem(label, key, icon?, children?) {
  return {
    key,
    icon,
    children,
    label
  };
}

const items = (isMemberType: boolean) => {
  if (isMemberType) {
    return [
      getItem((
        <Link to="/">Lớp của tôi</Link>
      ), '1', <PieChartOutlined />)
    ]
  }
  return [
    getItem((
      <Link to="/">Quản lý lớp</Link>
    ), '1', <PieChartOutlined />),
    getItem((
      <Link to="/member">Quản lý học viên</Link>
    ), '2', <TeamOutlined />),
    getItem((
      <Link to="/report-time-table">Thời khoá biểu</Link>
    ), '3', <DesktopOutlined />),
    getItem('Báo cáo', 'sub1', <UserOutlined />, [
      getItem((<Link to="/report/attend-detail">Kết quả học</Link>), '4'),
    ])
  ]
};

// eslint-disable-next-line react/prop-types
const MainLayout = ({ children }) => {
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state)

  const tempUser = JSON.parse(localStorage.getItem("user"))
  const userName = tempUser ? tempUser.name : JSON.parse(localStorage.getItem("member")).name

  const handleMenuItemClick = (key:string) => {
    if (key === "logout") {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("user")
      localStorage.removeItem("member")
      const targetPath = auth.isMemberType ? '/login-member' : '/login'
      navigate(targetPath);
      window.location.reload()
    }
    else {
      setChangePasswordVisible(true);
    }
  };

  const handleCancelChangePassword = () => {
    setChangePasswordVisible(false);
  };

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: !auth.isMemberType && (
        <Menu.Item onClick={() => handleMenuItemClick("changePassword")}>
          <SettingOutlined />
          <span className={styles.dropDownMenuText}> Đổi mật khẩu </span>
        </Menu.Item>
      ),
    },
    {
      key: '2',
      label: (
        <Menu.Item onClick={() => handleMenuItemClick("logout")}>
          <LogoutOutlined />
          <span className={styles.dropDownMenuText} > Đăng xuất </span>
        </Menu.Item>
      ),
    },
  ]

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <ChangePassword visible={changePasswordVisible} onCancel={handleCancelChangePassword} />
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className={styles.logos}> <span>DayHocTot.Edu.Vn</span> </div>
        <Menu className={styles['left-side-menu']} theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items(auth.isMemberType)} />
      </Sider>
      <Layout className="site-layout">
        <Header className={styles.layoutHeader}>
          <div className="user-profile">
            <Dropdown menu={{ items: menuItems }}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <Avatar alt="User Avatar" icon={<UserOutlined />} />
                <span className={styles.userName}>{userName}</span>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Tool của Đỗ Xuân Thành
        </Footer>
      </Layout>
    </Layout>
  );
}

export default MainLayout