
import { Table, Row, Button, Tag, Popover, Space } from 'antd';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { getTimeTables } from "app-redux/slices/report"
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import styles from './ReportTimeTable.module.css'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const ReportTimeTable = () => {
  const dispatch = useDispatch();
  const { report } = useSelector(state => state)

  useEffect(() => {
    console.log(
      "%cStop!",
      "color:orange;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );

    dispatch(getTimeTables())

  }, [])

  const membersOfClass = (timeTableObj) => {

    if (timeTableObj === undefined) {
      return []
    }
    return <ul>
      {timeTableObj.members.map(each => (<li key={each.id}>{each.name}</li>))}
    </ul>
  }

  const cellItem = (timeTableObj) => {
    const maxNumberToShow = 2
    const showMembers = timeTableObj.members != undefined ? timeTableObj.members.slice(0, maxNumberToShow) : undefined
    const shouldShowMore = timeTableObj.members != undefined && timeTableObj.members.length > maxNumberToShow
    return (
      <>
        {(timeTableObj != undefined && timeTableObj.tutorClass != undefined) ? timeTableObj.tutorClass.name : ""}
        {timeTableObj != undefined && showMembers != undefined ? showMembers.map(member => (
          <Tag color='green' key={member.id}>
            {member.name.length > 10 ? member.name.slice(0, 10) + '...' : member.name}
          </Tag>
        )) : ""}
        {shouldShowMore && (<div className={styles.moreTag}>
          <Popover content={membersOfClass(timeTableObj)} title="Danh sách học viên" trigger="hover">
            <Tag color="green">...</Tag>
          </Popover>
        </div>)}

      </>)
  }

  const columns = [
    {
      title: 'Ca học',
      width: 100,
      dataIndex: 'time',

    },
    {
      title: 'Thứ 2',
      dataIndex: 'mon',
      render: (_, { mon }) => (<>{cellItem(mon)}</>)
    },
    {
      title: 'Thứ 3',
      dataIndex: 'tue',
      render: (_, { tue }) => (<>{cellItem(tue)}</>)
    },
    {
      title: 'Thứ 4',
      dataIndex: 'wed',
      render: (_, { wed }) => (<>{cellItem(wed)}</>)
    },
    {
      title: 'Thứ 5',
      dataIndex: 'thu',
      render: (_, { thu }) => (<>{cellItem(thu)}</>)
    },
    {
      title: 'Thứ 6',
      dataIndex: 'fri',
      render: (_, { fri }) => (<>{cellItem(fri)}</>)
    },
    {
      title: 'Thứ 7',
      dataIndex: 'sat',
      render: (_, { sat }) => (<>{cellItem(sat)}</>)
    },
    {
      title: 'CN',
      dataIndex: 'sun',
      render: (_, { sun }) => (<>{cellItem(sun)}</>)
    },
  ];

  return (
    <div>
      <h1 className={styles.mainTitle}> Xem thời khoá biểu </h1>
      <Row justify="end" style={{paddingBottom: 10}}>
        <Space>
          <Button type="primary">
            <Link to="/time-table">Cài đặt TKB</Link>
          </Button>
        </Space>
      </Row>
      <Table columns={columns} dataSource={report.timeTables} pagination={false} scroll={{ x: 1000, y: "70vh" }}/>
    </div>
  )
}

export default connect(null, {})(ReportTimeTable);
