import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Space, Modal } from 'antd';
import { useParams } from "react-router-dom";

import { Exam } from "types/exam/Exam"
import QuestionItem from "../QuestionItem/QuestionItem"
import styles from "./AnswerExam.module.css"
import { useAnswerExamMutation, useGetExamDetailQuery, useGetExamDetailShuffleAnswersQuery } from "app-redux/slices/exam";
import { AnswerData } from 'types/exam/request/AnswerExamData';
import { showErrorMessage, showSuccessMessage } from 'helper/Helper';

const AnswerExam: React.FC = () => {
    const { examId } = useParams();
    const { data, error, isLoading, refetch } = useGetExamDetailShuffleAnswersQuery(examId)
    const [answers, setAnswers] = useState<AnswerData[]>([]);
    const [answerExam] = useAnswerExamMutation()
    const navigate = useNavigate();

    const questionItemAnswer = (questionId, answerId) => {
        const tempIndex = answers.findIndex(answer => answer.questionId === questionId)
        if (tempIndex !== -1) {
            const tempArray = [...answers]
            tempArray[tempIndex] = { questionId, selectedAnswer: answerId }
            setAnswers(tempArray)
            console.log("repalced answer: " + questionId)
        }
        else {
            const tempArray = [...answers]
            tempArray.push({ questionId, selectedAnswer: answerId })
            setAnswers(tempArray)
            console.log("added answer" + questionId)
        }
    }

    const handleSubmitClick = async () => {
        console.log(answers)
        if (answers.length !== exam.questions.length) {
            showErrorMessage("Hãy trả lời hết câu hỏi trước khi gửi kết quả bài làm")
            return
        }

        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có chắc chắn gửi bài làm ?',
            okText: "Ok, gửi bài",
            cancelText: "Huỷ bỏ",
            async onOk() {
                const answerExamData = {
                    examId,
                    answers
                }
        
                try {
                    const result = await answerExam(answerExamData).unwrap();
                    showSuccessMessage("Bài làm đã được gửi thành công!")
                    navigate(-1)
        
                } catch (error) {
                    showErrorMessage("Có lỗi xảy ra khi gửi bài làm")
                }
            },
            onCancel() {
                console.log('Cancelled');
                return
            },
        });

    }

    const renderQuestion = (exam: Exam) => {
        return exam.questions.map((question, index) => (
            <div key={question._id} className={styles.questionItem}>
                <QuestionItem question={question} questionIndex={index} questionItemAnswer={questionItemAnswer} />
            </div>
        ))
    }

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {"some error"}</div>;

    const exam = data;
    if (data === undefined) {
        <div>Error: {"some error"}</div>;
    }

    return (
        <div>
            {renderQuestion(exam)}
            <Row justify="center" style={{ paddingBottom: 10 }}>
                <Space>
                    <Button type="primary" onClick={handleSubmitClick}>Gửi bài làm</Button>
                </Space>
            </Row>
        </div>
    )
}

export default AnswerExam